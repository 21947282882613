@mixin scroll-bar($white, $black, $dark-grey) {
  ::-webkit-scrollbar-track  {
    background-color: $white;

    .app-dark & {
      background-color: $dark-grey;
    }
  }

  ::-webkit-scrollbar {
    width: 5px;

    .app-mobile & {
      width: 2px;
    }
  }

  ::-webkit-scrollbar-thumb {
    background-color: $black;

    .app-dark & {
      background-color: $white;
    }

    .app-mobile & {
      opacity: 0.2;
    }
  }
}
