@import "./focused";
@import "./overrides";
@import "./scroll-bar";

$white: #ffffff;
$black: #000000;
$dark-grey: #303030;
$light-grey: #adafbc;
$red: #ff0000;
$light-red: #ff6969;

@include focused($white, $black);
@include overrides($white, $black, $dark-grey);
@include scroll-bar($white, $black, $dark-grey);

.app-background {
  background: $white !important;

  .app-dark & {
    background: $dark-grey !important;
  }
}

.app-text {
  color: $black !important;

  .app-dark & {
    color: $white !important;
  }
}

.app-secondary-text {
  color: $dark-grey !important;

  .app-dark & {
    color: $light-grey !important;
  }
}

.app-error-text {
  color: $red !important;

  .app-dark & {
    color: $light-red !important;
  }
}

.app-border {
  border-color: $black !important;

  .app-dark & {
    border-color: $white !important;
  }
}
