@mixin overrides($white, $black, $dark-grey) {
  a:hover {
    text-decoration: none !important;
  }

  .cdk-overlay-backdrop {
    cursor: pointer;
  }

  .cdk-overlay-pane {
    border: 3px solid;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px $white inset !important;
    -webkit-text-fill-color: $black;

    .app-dark & {
      box-shadow: 0 0 0 30px $dark-grey inset !important;
      -webkit-text-fill-color: $white;
    }
  }

  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }

  body,
  html {
    height: 100%;
    font-size: 14px;
    cursor: default;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;

    * {
      font-family: "Press Start 2P";
    }
  }

  a {
    text-decoration: none;
  }

  a,
  button {
    cursor: default;
  }

  h1,
  h2,
  h3,
  h4 {
    margin: 0;
    word-break: break-word;
  }

  h1 {
    font-size: 28px;

    .app-mobile & {
      font-size: 26px;
    }
  }

  label {
    font-size: 11px;
  }

  iframe {
    border: none;
  }

  img {
    user-select: none;
  }
}
